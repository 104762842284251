import { createTheme, responsiveFontSizes } from "@mui/material"

const themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#FFFFFF',
        }
    },
    typography: {
        fontFamily: [
            'Belleza'
        ],
        h1: {
            fontSize: '4rem',
            fontStyle: 'normal',
            fontWeight: 400,
            paddingTop: '1rem'
        },
        h2: {
            fontSize: '3rem',
            fontStyle: 'normal',
            fontWeight: 400,
            paddingTop: '1rem'
        },
        h3: {
            fontSize: '2rem',
            fontStyle: 'normal',
            fontWeight: 400,
            paddingTop: '1rem'
        },
        h4: {
            marginBottom: '1rem'
        },
        body: {
            fontSize: '1.5rem',
            fontStyle: 'normal',
            fontWeight: 400,
            paddingTop: '1rem'
        },
        dateText: {
            fontSize: '1.25rem',
            fontWeight: 500,
            color: '#666',
        },
    },
    components: {
        MuiCardContent: {
            styleOverrides: {
                root: {
                    minHeight: '35em',
                    padding: '1rem',
                    overflow: 'auto'
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.experienceDescription': {
                        minHeight: '12em',
                        overflow: 'auto',
                        padding: '1rem'
                    }
                }
            }
        },
    },
}

const theme = responsiveFontSizes(
    createTheme(themeOptions)
)


export default theme